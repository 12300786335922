<template>
  <div class="agreement wrapper">
    <p class="title-top" />
    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
      v-html="$t('rules.TreasureBox')"
    />
  </div>
</template>

<script>
export default {
  name: 'TreasureBoxTerms',
  metaInfo: {
    // title: 'Terms of Use --Bifrost'
  }
}
</script>

<style lang="less">
  @import "~@/assets/styles/agreement.less";
</style>

<style lang="less" scoped>
  .wrapper{
    padding: 0 5%;
    background:#F0F0F0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
</style>
